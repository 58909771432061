<template>
  <v-container class="container">
    <v-breadcrumbs :items="breadcrumbItems"/>
    <v-card>
        <v-card-title class="subtitle">Organizations</v-card-title>

        <div v-if="orgs">
            <v-card-text class="pb-0">
            <p class="mb-0">Create and manage your own organizations!</p>
            </v-card-text>      
            <v-card-text class="pb-0" v-if="orgs.length == 0">
            <i class="mb-0">You haven't created an organizations yet.</i>
            </v-card-text>      

            <v-row dense class="mx-4">
              <v-col v-for="(item, idx) in orgs" :key="item.id"
                cols="12"
                sm="6"
                md="4"
                lg="3"
              >
                <GroupCard :item="item" :index="idx" route-name="orgmanagerView"></GroupCard>
              </v-col>
            </v-row>


            <p>
                <v-btn color="primary" :to="{name:'orgmanagerCreate'}" class="ml-4">Create a new organization</v-btn>
                <v-btn text color="primary" :href="tenant.eventManagerFaqUrl" class="ml-4">FAQs</v-btn>
            </p>
            <br/>
        </div>
        <div v-else>
            <v-skeleton-loader v-if="!user || $store.getters.loading" type="table"></v-skeleton-loader>
        </div>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import orgManagerService from "@/services/orgManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import GroupCard from "@/components/GroupCard.vue";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Organizations",
  components: {
    GroupCard,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      profile: null,
      orgs: null,
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async getProfile() {
      if (this.user) {
        this.orgs = (await orgManagerService.getOwnedEvents()).data.data;
      }
    },

    resolveEventManagerRoute(id) {
      return {name: 'orgmanagerView', params: {id: id}}
    },
  },
  computed: {
    breadcrumbItems() {
      return [
        { text: 'Event Manager', disabled: true },
        { text: 'Organizations', disabled: true },
      ];
    },
    ...mapGetters({
      user: "user"
    })
  },

};
</script>
<style lang="scss">
</style>

